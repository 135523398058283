import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)

// 解决vue-router在3.1.0版本之后，push和replace方法会返回一个promise对象, 跳转到相同的路由报promise uncaught异常
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}

/* Layout */
import Layout from "@/layout"

/**
 *
 * hidden: true                   如果设置为 true，项目将不会显示在侧边栏中(默认为 false)
 * alwaysShow: true               如果设置 true，这样它就会忽略之前定义的规则，一直显示根路由
 *                                如果不设置 alwaysShow，当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式，
 *                                只有一个时，会将那个子路由当做根路由显示在侧边栏
 * redirect: noRedirect           当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
    roles: ['admin','editor']    设置该路由进入的权限，支持多个权限叠加
    title: 'title'               设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'/'el-icon-x' 设置该路由的图标，支持 el-icon-x element-ui 的 icon
    noCache: true                设置为true，则不会被 <keep-alive> 缓存(默认 false)
    affix: true                  设置为false，则不会在breadcrumb面包屑中显示(默认 true)
    breadcrumb: false            设置为true，它则会固定在tags-view中(默认 false)
    activeMenu: '/example/list'  当路由设置了该属性，则会高亮相对应的侧边栏
  }
 */

/**
 * constantRoutes
 * 没有权限要求的基本页面
 * 所有角色都可以访问
 */
export const constantRoutes = [
  {
    path: "/redirect",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/redirect/:path(.*)",
        component: () => import("@/views/redirect/index")
      }
    ]
  },
  {
    path: "/login",
    component: () => import("@/views/login/index"),
    hidden: true
  },
  {
    path: "/404",
    component: () => import("@/views/error-page/404"),
    hidden: true
  },
  {
    path: "/401",
    component: () => import("@/views/error-page/401"),
    hidden: true
  },
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "home",
        component: () => import("@/views/home/index"),
        name: "home",
        meta: { title: "首页", icon: "el-icon-s-home", affix: true }
      }
    ]
  },
  {
    path: "/personal",
    component: Layout,
    redirect: "/personal/index",
    hidden: true,
    children: [
      {
        path: "index",
        component: () => import("@/views/personal/index"),
        name: "personal",
        meta: { title: "个人中心" }
      }
    ]
  }
]

/**
 * asyncRoutes
 * 需要根据用户角色动态加载的路由集合
 */
export const asyncRoutes = [
  {
    path: "/permissions",
    component: Layout,
    redirect: "/permissions/menu",
    name: "permissions",
    meta: {
      title: "权限管理",
      icon: "el-icon-s-tools",
      roles: ["admin"]
    },
    children: [
      {
        path: "httpurl",
        component: () => import("@/views/permissions/httpurl"),
        name: "permissions_httpurl",
        meta: {
          title: "接口管理"
        }
      },
      {
        path: "menu",
        component: () => import("@/views/permissions/menu"),
        name: "permissions_menu",
        meta: {
          title: "菜单管理"
        }
      },
      {
        path: "role",
        component: () => import("@/views/permissions/role"),
        name: "permissions_role",
        meta: {
          title: "角色管理"
        }
      },
      {
        path: "user",
        component: () => import("@/views/permissions/user"),
        name: "permissions_user",
        meta: {
          title: "账号管理"
        }
      },
      {
        path: "log",
        component: () => import("@/views/permissions/log"),
        name: "permissions_log",
        meta: {
          title: "操作日志"
        }
      }
    ]
  },
  {
    path: "/task",
    component: Layout,
    redirect: "/task/banner",
    name: "task",
    alwaysShow: true,
    meta: {
      title: "功能管理",
      icon: "el-icon-s-tools"
    },
    children: [
      {
        path: "articleType",
        component: () => import("@/views/task/articleType"),
        name: "task_articleType",
        meta: {
          title: "文章分类"
        }
      },
      {
        path: "article",
        component: () => import("@/views/task/article"),
        name: "task_article",
        meta: {
          title: "文章管理"
        }
      },
      {
        path: "cover",
        component: () => import("@/views/task/cover"),
        name: "task_cover",
        meta: {
          title: "账本封面"
        }
      },
      {
        path: "widgetLabel",
        component: () => import("@/views/task/widgetLabel"),
        name: "task_widgetLabel",
        meta: {
          title: "小组件标签"
        }
      },
      {
        path: "widgetCover",
        component: () => import("@/views/task/widgetCover"),
        name: "task_widgetCover",
        meta: {
          title: "小组件封面"
        }
      }
    ]
  },
  {
    path: "/account",
    component: Layout,
    redirect: "/account/user",
    name: "account",
    alwaysShow: true,
    meta: {
      title: "用户管理",
      icon: "el-icon-s-tools"
    },
    children: [
      {
        path: "user",
        component: () => import("@/views/account/user"),
        name: "account_user",
        meta: {
          title: "用户列表"
        }
      }
    ]
  },
  {
    path: "/message",
    component: Layout,
    redirect: "/message/system",
    name: "message",
    alwaysShow: true,
    meta: {
      title: "消息管理",
      icon: "el-icon-s-tools"
    },
    children: [
      {
        path: "system",
        component: () => import("@/views/message/system"),
        name: "message_system",
        meta: {
          title: "系统消息"
        }
      },
      {
        path: "xinshou",
        component: () => import("@/views/message/xinshou"),
        name: "message_xinshou",
        meta: {
          title: "新用户消息"
        }
      },
      {
        path: "questionnaire",
        component: () => import("@/views/message/questionnaire"),
        name: "message_questionnaire",
        meta: {
          title: "问卷调查"
        }
      }
    ]
  },
  {
    path: "/shop",
    component: Layout,
    redirect: "/shop/type",
    name: "shop",
    alwaysShow: true,
    meta: {
      title: "商店管理",
      icon: "el-icon-s-tools"
    },
    children: [
      {
        path: "type",
        component: () => import("@/views/shop/type"),
        name: "shop_type",
        meta: {
          title: "商品分类"
        }
      },
      {
        path: "materiel",
        component: () => import("@/views/shop/materiel"),
        name: "shop_materiel",
        meta: {
          title: "商品物料"
        }
      },
      {
        path: "goods",
        component: () => import("@/views/shop/goods"),
        name: "shop_goods",
        meta: {
          title: "商品配置"
        }
      },
      {
        path: "exchange",
        component: () => import("@/views/shop/exchange"),
        name: "shop_exchange",
        meta: {
          title: "兑换记录"
        }
      }
    ]
  },
  {
    path: "/medal",
    component: Layout,
    redirect: "/medal/type",
    name: "medal",
    alwaysShow: true,
    meta: {
      title: "成就勋章",
      icon: "el-icon-s-tools"
    },
    children: [
      {
        path: "type",
        component: () => import("@/views/medal/type"),
        name: "medal_type",
        meta: {
          title: "勋章类别"
        }
      },
      {
        path: "config",
        component: () => import("@/views/medal/config"),
        name: "medal_config",
        meta: {
          title: "勋章配置"
        }
      }
    ]
  },
  {
    path: "/task2",
    component: Layout,
    redirect: "/task2/jifen",
    name: "task2",
    alwaysShow: true,
    meta: {
      title: "任务管理",
      icon: "el-icon-s-tools"
    },
    children: [
      {
        path: "jifen",
        component: () => import("@/views/task2/jifen"),
        name: "task2_jifen",
        meta: {
          title: "用户积分"
        }
      },
      {
        path: "xinshou",
        component: () => import("@/views/task2/xinshou"),
        name: "task2_xinshou",
        meta: {
          title: "新手任务"
        }
      },
      {
        path: "richang",
        component: () => import("@/views/task2/richang"),
        name: "task2_richang",
        meta: {
          title: "日常任务"
        }
      },
      {
        path: "xinshou_qd",
        component: () => import("@/views/task2/xinshou_qd"),
        name: "task2_xinshou_qd",
        meta: {
          title: "新手签到"
        }
      },
      {
        path: "def_qd",
        component: () => import("@/views/task2/def_qd"),
        name: "task2_def_qd",
        meta: {
          title: "默认签到"
        }
      },
      {
        path: "richang_qd",
        component: () => import("@/views/task2/richang_qd"),
        name: "task2_richang_qd",
        meta: {
          title: "日常签到"
        }
      }
    ]
  },
  {
    path: "/recharge",
    component: Layout,
    redirect: "/recharge/vip",
    name: "recharge",
    alwaysShow: true,
    meta: {
      title: "充值管理",
      icon: "el-icon-s-tools"
    },
    children: [
      {
        path: "vip",
        component: () => import("@/views/recharge/vip"),
        name: "recharge_vip",
        meta: {
          title: "会员充值"
        }
      },
      {
        path: "integral",
        component: () => import("@/views/recharge/integral"),
        name: "recharge_integral",
        meta: {
          title: "积分充值"
        }
      },
      {
        path: "order",
        component: () => import("@/views/recharge/order"),
        name: "recharge_order",
        meta: {
          title: "订单列表"
        }
      },
      {
        path: "around",
        component: () => import("@/views/recharge/around"),
        name: "recharge_around",
        meta: {
          title: "周边兑换"
        }
      }
    ]
  },
  {
    path: "/taste",
    component: Layout,
    redirect: "/taste/muck",
    name: "taste",
    alwaysShow: true,
    meta: {
      title: "趣味玩法",
      icon: "el-icon-s-tools"
    },
    children: [
      {
        path: "muck",
        component: () => import("@/views/taste/muck"),
        name: "taste_muck",
        meta: {
          title: "肥料"
        }
      },
      {
        path: "charm",
        component: () => import("@/views/taste/charm"),
        name: "taste_charm",
        meta: {
          title: "魅力/劳动值"
        }
      },
      {
        path: "mogu",
        component: () => import("@/views/taste/mogu"),
        name: "taste_mogu",
        meta: {
          title: "蘑菇币兑换"
        }
      }
    ]
  },
  // 404页面一定要放在最后！！！
  { path: "*", redirect: "/404", hidden: true }
]

const createRouter = () =>
  new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
  })

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
