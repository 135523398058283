import http from "@/utils/request"

export const $api = {
  // 基础权限 start
  login: (json) => http.post("/admin/v1/passport/login", json),
  getPhoneCode: (json) => http.post("/admin/v1/passport/send-phone-code", json),
  logout: () => http.post("/admin/v1/passport/logout"),
  menuGetList: () => http.post("/admin/v1/menu/get-list"),
  menuStore: (json) => http.post("/admin/v1/menu/store", json),
  menuDelete: (json) => http.delete("/admin/v1/menu/delete", { params: json }),
  menuGetInfo: (json) => http.post("/admin/v1/menu/get-info", json),
  menuPermissionsList: (json) => http.post("/admin/v1/menu/permissions-list", json),
  menuGetUserMenu: () => http.post("/admin/v1/menu/get-user-menu"),
  rolesGetList: (json) => http.post("/admin/v1/roles/get-list", json),
  rolesGetPermissions: (json) => http.post("/admin/v1/roles/get-permissions", json),
  rolesSavePermissions: (json) => http.post("/admin/v1/roles/save-permissions", json),
  rolesGetInfo: (json) => http.post("/admin/v1/roles/get-info", json),
  rolesDelete: (json) => http.delete("/admin/v1/roles/delete", { params: json }),
  rolesStore: (json) => http.post("/admin/v1/roles/store", json),
  permissionsGetList: (json) => http.post("/admin/v1/permissions/get-list", json),
  permissionsStore: (json) => http.post("/admin/v1/permissions/store", json),
  permissionsGetInfo: (json) => http.post("/admin/v1/permissions/get-info", json),
  permissionsDelete: (json) => http.delete("/admin/v1/permissions/delete", { params: json }),
  adminGetList: (json) => http.post("/admin/v1/admin/get-list", json),
  adminStore: (json) => http.post("/admin/v1/admin/store", json),
  adminGetInfo: (json) => http.post("/admin/v1/admin/get-info", json),
  rolesGetRoles: (json) => http.post("/admin/v1/roles/get-roles", json),
  adminUserInfo: (json) => http.post("/admin/v1/admin/user-info", json),
  adminDelete: (json) => http.delete("/admin/v1/admin/delete", { params: json }),
  logGetList: (json) => http.post("/admin/v1/log/get-list", json),
  updateUser: (json) => http.post("/admin/v1/passport/update-user", json),
  // 基础权限 end
  aliInfo: () => http.get("/admin/v1/bill/upload/get-upload-token"),
  userList: (json) => http.get("/admin/v1/bill/member/list", { params: json }),
  userStatus: (json) => http.post("/admin/v1/bill/member/logout-status", json),
  userDelete: (json) => http.delete("/admin/v1/bill/member/delete", { params: json }),
  userStore: (json) => http.post("/admin/v1/bill/member/check-level", json),
  articleTypeList: (json) => http.get("/admin/v1/bill/article-type/list", { params: json }),
  articleTypeAdd: (json) => http.post("/admin/v1/bill/article-type/save", json),
  articleTypeShow: (json) => http.get("/admin/v1/bill/article-type/show", { params: json }),
  articleTypeUpdate: (json) => http.post("/admin/v1/bill/article-type/update", json),
  articleTypeDelete: (json) => http.delete("/admin/v1/bill/article-type/delete", { params: json }),
  articleTypeAll: (json) => http.get("/admin/v1/bill/article-type/all", { params: json }),
  articleList: (json) => http.get("/admin/v1/bill/article/list", { params: json }),
  articleAdd: (json) => http.post("/admin/v1/bill/article/save", json),
  articleShow: (json) => http.get("/admin/v1/bill/article/show", { params: json }),
  articleUpdate: (json) => http.post("/admin/v1/bill/article/update", json),
  articleDelete: (json) => http.delete("/admin/v1/bill/article/delete", { params: json }),
  articleChannel: () => http.get("/admin/v1/bill/channel/all"),
  sysNoticeLlist: (json) => http.get("/admin/v1/bill/sys-notice/list", { params: json }),
  sysNoticeSave: (json) => http.post("/admin/v1/bill/sys-notice/save", json),
  getMsg: (json) => http.get("/admin/v1/bill/im/greet-word", { params: json }),
  postMsg: (json) => http.post("/admin/v1/bill/im/greet-word", json),
  itemCateList: (json) => http.get("/admin/v1/bill/item-cate/list", { params: json }),
  itemCateAll: (json) => http.get("/admin/v1/bill/item-cate/index", { params: json }),
  itemCateAdd: (json) => http.post("/admin/v1/bill/item-cate/add", json),
  itemCateUpdate: (json) => http.post("/admin/v1/bill/item-cate/update", json),
  itemCateDelete: (json) => http.delete("/admin/v1/bill/item-cate/delete", { params: json }),
  itemCateInfo: (json) => http.post("/admin/v1/bill/item-cate/info", json),
  itemList: (json) => http.get("/admin/v1/bill/item/list", { params: json }),
  itemListAll: (json) => http.get("/admin/v1/bill/item/all-item", { params: json }),
  itemAdd: (json) => http.post("/admin/v1/bill/item/add", json),
  itemUpdate: (json) => http.post("/admin/v1/bill/item/update", json),
  itemDelete: (json) => http.delete("/admin/v1/bill/item/delete", { params: json }),
  itemInfo: (json) => http.post("/admin/v1/bill/item/info", json),
  materialList: (json) => http.get("/admin/v1/bill/material/list", { params: json }),
  materialAdd: (json) => http.post("/admin/v1/bill/material/add", json),
  materialUpdate: (json) => http.post("/admin/v1/bill/material/update", json),
  materialDelete: (json) => http.delete("/admin/v1/bill/material/delete", { params: json }),
  materialInfo: (json) => http.get("/admin/v1/bill/material/show", { params: json }),
  materialDef: (json) => http.post("/admin/v1/bill/material/default", json),
  materialAll: (json) => http.get("/admin/v1/bill/material/index", { params: json }),
  materialPosition: (json) => http.get("/admin/v1/bill/material/position-list", { params: json }),
  medalList: (json) => http.get("/admin/v1/bill/growth-medal-cate/list", { params: json }),
  medalAdd: (json) => http.post("/admin/v1/bill/growth-medal-cate/add", json),
  medalUpdate: (json) => http.post("/admin/v1/bill/growth-medal-cate/update", json),
  medalDelete: (json) => http.delete("/admin/v1/bill/growth-medal-cate/delete", { params: json }),
  medalInfo: (json) => http.post("/admin/v1/bill/growth-medal-cate/info", json),
  medalAll: (json) => http.get("/admin/v1/bill/growth-medal-cate/index", { params: json }),
  exchangeList: (json) => http.get("/admin/v1/bill/item/records", { params: json }),
  growthMedalList: (json) => http.get("/admin/v1/bill/growth-medal/list", { params: json }),
  growthMedalAdd: (json) => http.post("/admin/v1/bill/growth-medal/add", json),
  growthMedalUpdate: (json) => http.post("/admin/v1/bill/growth-medal/update", json),
  growthMedalDelete: (json) => http.delete("/admin/v1/bill/growth-medal/delete", { params: json }),
  growthMedalInfo: (json) => http.post("/admin/v1/bill/growth-medal/info", json),
  growthMedalEvent: (json) => http.get("/admin/v1/bill/growth-medal/event", { params: json }),
  taskPoint: (json) => http.get("/admin/v1/bill/task/point", { params: json }),
  taskEvent: (json) => http.get("/admin/v1/bill/task/event", { params: json }),
  taskRemark: (json) => http.get("/admin/v1/bill/task/remark", { params: json }),
  taskList: (json) => http.get("/admin/v1/bill/task/list", { params: json }),
  taskAdd: (json) => http.post("/admin/v1/bill/task/add", json),
  taskEdit: (json) => http.post("/admin/v1/bill/task/update", json),
  taskShow: (json) => http.get("/admin/v1/bill/task/show", { params: json }),
  taskDelete: (json) => http.delete("/admin/v1/bill/task/delete", { params: json }),
  taskStatus: (json) => http.post("/admin/v1/bill/task/status", json),
  levelList: (json) => http.get("/admin/v1/bill/level/list", { params: json }),
  levelAdd: (json) => http.post("/admin/v1/bill/level/add", json),
  levelUpdate: (json) => http.post("/admin/v1/bill/level/update", json),
  levelInfo: (json) => http.post("/admin/v1/bill/level/info", json),
  levelDelete: (json) => http.delete("/admin/v1/bill/level/delete", { params: json }),
  levelGift: (json) => http.get("/admin/v1/bill/level/gift", { params: json }),
  levelInfoChannel: (json) => http.get("/admin/v1/bill/level/info-channel", { params: json }),
  levelSetChannel: (json) => http.post("/admin/v1/bill/level/set-channel", json),
  itemIntegralList: (json) => http.get("/admin/v1/bill/item-integral/list", { params: json }),
  itemIntegralAdd: (json) => http.post("/admin/v1/bill/item-integral/add", json),
  itemIntegralInfo: (json) => http.post("/admin/v1/bill/item-integral/info", json),
  itemIntegralDelete: (json) => http.delete("/admin/v1/bill/item-integral/delete", { params: json }),
  itemIntegralUpdate: (json) => http.post("/admin/v1/bill/item-integral/update", json),
  orderList: (json) => http.get("/admin/v1/bill/order/list", { params: json }),
  trackOrderCity: (json) => http.get("/admin/v1/bill/track-order/city", { params: json }),
  trackOrderList: (json) => http.get("/admin/v1/bill/track-order/list", { params: json }),
  trackOrderInfo: (json) => http.post("/admin/v1/bill/track-order/info", json),
  trackOrderUpdate: (json) => http.post("/admin/v1/bill/track-order/update", json),
  signInList: (json) => http.get("/admin/v1/bill/sign-in/list", { params: json }),
  signInInfo: (json) => http.get("/admin/v1/bill/sign-in/info", { params: json }),
  signInUpdate: (json) => http.post("/admin/v1/bill/sign-in/update", json),
  signInDaily: (json) => http.get("/admin/v1/bill/sign-in/daily-list", { params: json }),
  signInNewWeek: (json) => http.get("/admin/v1/bill/sign-in/new-week", { params: json }),
  signInUpdateWeek: (json) => http.post("/admin/v1/bill/sign-in/update-week", json),
  accountIconList: (json) => http.get("/admin/v1/bill/account-icon/list", { params: json }),
  accountIconAdd: (json) => http.post("/admin/v1/bill/account-icon/add", json),
  accountIconUpdate: (json) => http.post("/admin/v1/bill/account-icon/update", json),
  accountIconInfo: (json) => http.post("/admin/v1/bill/account-icon/info", json),
  accountIconDelete: (json) => http.delete("/admin/v1/bill/account-icon/delete", { params: json }),
  consumptionCate: (json) => http.get("/admin/v1/bill/gameplay/consumption-cate", { params: json }),
  fertilizerRecordList: (json) => http.get("/admin/v1/bill/gameplay/fertilizer-record-list", { params: json }),
  userRecord: (json) => http.get("/admin/v1/bill/gameplay/user-record", { params: json }),
  moguRecord: (json) => http.get("/admin/v1/bill/gameplay/mogu-record", { params: json }),
  moduleTypeList: (json) => http.get("/admin/v1/bill/module/module-type-list", { params: json }),
  addModuleType: (json) => http.post("/admin/v1/bill/module/add-module-type", json),
  updateModuleType: (json) => http.post("/admin/v1/bill/module/update-module-type", json),
  moduleList: (json) => http.get("/admin/v1/bill/module/list", { params: json }),
  moduleAdd: (json) => http.post("/admin/v1/bill/module/add", json),
  moduleUpdate: (json) => http.post("/admin/v1/bill/module/update", json),
  moduleInfo: (json) => http.get("/admin/v1/bill/module/info", { params: json }),
  moduleUpdateInfo: (json) => http.post("/admin/v1/bill/module/update-info", json),
  questionnaireList: (json) => http.get("/admin/v1/bill/questionnaire/log-list", { params: json }),
}
